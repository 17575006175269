import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';
import { licenseKey } from '@root/devextreme-license';
import config from 'devextreme/core/config';
import themes from 'devextreme/ui/themes';

config({
  licenseKey,
});

themes.initialized(() => {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
