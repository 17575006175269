import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SplashScreenService } from '@core/modules/splash-screen/splash-screen.service';

@Component({
  selector: 'pps-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  standalone: false,
})
export class SplashScreenComponent implements OnInit {
  @ViewChild('splashScreen', { static: true }) public splashScreen!: ElementRef;

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _splashScreenService: SplashScreenService,
  ) {}

  public ngOnInit(): void {
    this._splashScreenService.init(this._elementRef);
  }
}
